.centered-container {
    font-family: 'Roboto Mono', monospace;
    font-size: 30px;
    font-weight: 10;
    text-decoration: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

a.glowing-text {
    color: #00FFFF;
    text-decoration: none;
    transition: color 0.3s ease;
    display: inline-block;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;
}

.glowing-text {
    color: #00FFFF;
    text-shadow: 0 0 10px #40E0D0, 0 0 20px #40E0D0, 0 0 30px #40E0D0;
    margin: 10px;
}

a.glowing-text:hover {
    animation: glow-pulse 5s infinite;
}

a.glowing-text::before,
a.glowing-text::after {
    content: '';
    position: absolute;
    background: #00FFFF;
    transition: transform 0.3s ease;
}

/* Top and Bottom Borders */
a.glowing-text::before {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 2px;
    transform: scaleX(0);
    transform-origin: left;
}

/* Left and Right Borders */
a.glowing-text::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    transform: scaleY(0);
    transform-origin: top;
}

a.glowing-text:hover::before {
    transform: scaleX(1);
}

a.glowing-text:hover::after {
    transform: scaleY(1);
}