body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
    background-color: #0f0f23;
    color: #ffffff;
}

.App-header {
    position: relative;
    z-index: 0;
    /* Higher than the particles */
    padding: 15px;
}

.App-header h1 {
    line-height: .8;
    margin-top: 10px;
    font-weight: 500;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-top: 5px;
}

.App-header p {
    line-height: 1.0;
    margin: 0;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-top: 0px;
}

.glowing-text {
    color: #00FFFF;
    text-shadow: 0 0 10px #40E0D0, 0 0 20px #40E0D0, 0 0 30px #40E0D0, 0 0 40px #40E0D0;
}